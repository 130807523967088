.main-image-div{
    position: relative;
}

.option-image-div{
    position: absolute;
    display: none;
    background: #1b1e78;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    text-align: center;
}

.main-image-div:hover .option-image-div{
    display: block;
    animation: fadeIn 0.8s;
}

.image-container{
    cursor: pointer;
}

.image-container:hover {
    animation: shake 1s;
    animation-iteration-count: infinite;
}
  
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes fadeIn {
    from {
         opacity: 0;
    }
    to {
         opacity: .3;
    }
}