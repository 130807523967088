$authBg: #3C51DB;

.nano-app {
    background: $authBg;
}

.nano-wrapper {
    width: 100%;
    @include ltr-rtl("padding-left", var(--nrui-sidebar-occupy-start, 0));
    will-change: auto;
    @include transition(padding .15s);
  }

.nano-app-auth {
    display: flex;
    min-height: 100vh;
}

.nano-app-auth-logo {
    text-align: center !important;
}

.nano-app-h-100 {
    height: 100vh;
}

.not-allowed-cursor {
    cursor: not-allowed;
    opacity: 0.5;
}