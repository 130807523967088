$white: #ffffff;
$black: #000215;
$blue: #4266ce;
$red: #e24242;

.n-custom-card-class,
.n-custom-button-class,
.n-custom-form-input-group-text-class,
.n-custom-form-input-class{
    cursor: auto;
}

.n-custom-link-class{
    cursor: pointer;
}

.n-custom-sidebar-toggler-class{
    background: $white;
    border: 2px solid $blue;
}
.n-custom-footer-class{
    background: $white;
}
.n-custom-sidebar-brand-class{
    background: $white;
    border: 2px solid $blue;
}

.n-custom-sidebar-nav-class{
    background: $black;
}

.n-custom-sidebar-nav-class .n-custom-nav-link-class.active{
    color: $white;
    background: $blue;
}

.n-custom-widgit-button-class {
    cursor: pointer;
}